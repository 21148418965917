import React, { useRef } from "react";
import tw from "twin.macro";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useOpaqueNavbar } from "../utils";
import { Navbar, SEO } from "../components";
import EarLabLogo from "../images/svg/logo-ear-lab.svg";
import { earLabSections } from "../config";

import Otoscopy from "../images/svg/video-otoscopy-icon.svg";
import Tympanometry from "../images/svg/tympanometry-icon.svg";
import Perception from "../images/svg/speech-perception-icon.svg";
import Tinnitus from "../images/svg/tinnitus-assessment-icon.svg";
import Micro from "../images/svg/micro-suction-icon.svg";
import Protection from "../images/svg/hearing-protection-icon.svg";
import Sleep from "../images/svg/sleep-sound-icon.svg";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import { LocationBlock } from "../components/common";

const images: { [key: string]: EmotionJSX.Element } = {
  Otoscopy: <Otoscopy tw="w-72" />,
  Tympanometry: <Tympanometry tw="w-72" />,
  Perception: <Perception tw="w-72" />,
  Tinnitus: <Tinnitus tw="w-72" />,
  Micro: <Micro tw="w-72" />,
  Protection: <Protection tw="w-72" />,
  Sleep: <Sleep tw="w-72" />,
};

const s = {
  baseButton: tw`text-center uppercase transition-all duration-300 border-2 cursor-pointer letter-spacing[1.5px] font-display py-[10px] px-4 bg-transparent`,
  darkButton: tw`text-raisin border-raisin hover:(bg-raisin text-white)`,
  whiteButton: tw`text-white border-white hover:(bg-white text-raisin)`,
};

const EarLab = () => {
  const bgImageRef = useRef(null);
  const navbarOpaque = useOpaqueNavbar(bgImageRef);
  return (
    <>
      <SEO title="Ear Lab - Hearing Assessments & Treatments" />
      <Navbar opaque={navbarOpaque} />
      <section aria-label="Ear Lab Intro">
        <div tw="grid w-full" ref={bgImageRef}>
          <StaticImage
            src="../images/earlab.jpg"
            alt=""
            layout="fullWidth"
            placeholder="blurred"
            loading="eager"
            tw="h-screen grid-area[1/1]"
            quality={90}
          />
          <div tw="absolute top-0 w-full h-screen bg-black/20" />
          <div tw="grid grid-area[1/1] place-items-center px-4">
            <EarLabLogo tw="fill-current text-white z-10 max-w-lg w-full lg:-mt-14" />
          </div>
        </div>
      </section>
      <main>
        <section aria-label="The Ear Lab" tw="bg-honeydew">
          <div tw="pt-12 lg:py-24">
            <div tw="max-w-screen-xl mx-auto">
              {earLabSections.map(({ header, text, to, imageKey }, idx) => (
                <div
                  key={header}
                  tw="flex flex-col justify-center items-stretch"
                  css={[
                    idx % 2 === 0 ? tw`lg:flex-row` : tw`lg:flex-row-reverse`,
                  ]}
                >
                  <div tw="flex-basis[50%] w-full min-h-[500px] flex items-center justify-center bg-sonic">
                    {imageKey === "Assessment" ? (
                      <StaticImage
                        src="../images/hearing-assessment.png"
                        alt={header}
                        height={460}
                      />
                    ) : (
                      images[imageKey]
                    )}
                  </div>
                  <div tw="flex-basis[50%] flex w-full h-full min-h-[500px] bg-white px-5 py-24 lg:p-12">
                    <div tw="text-center flex flex-col justify-center items-center max-w-prose mx-auto">
                      <h2 tw="mb-8 font-display text-[40px] letter-spacing[2.5px] md:(mb-8 text-[50px] letter-spacing[3.5px]) uppercase">
                        {header}
                      </h2>
                      <p
                        tw="leading-relaxed font-light"
                        css={[to && tw`mb-8`]}
                        dangerouslySetInnerHTML={{ __html: text }}
                      />
                      {!!to && (
                        <button css={[s.baseButton, s.darkButton]}>
                          <Link to={to}>
                            <span tw="font-display font-semibold uppercase text-base leading-none">
                              Read More
                            </span>
                          </Link>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div tw="max-w-screen-xl lg:pb-24 mx-auto">
            <div tw="grid relative bg-[#9B958E] py-32 md:py-0">
              <div tw="hidden md:absolute top-0 w-full h-full bg-black/20 z-10" />
              <StaticImage
                width={1280}
                src="../images/ear-lab-hero.jpg"
                alt=""
                quality={90}
                placeholder="blurred"
                layout="constrained"
                tw="grid-area[1/1] hidden md:block"
              />
              <div tw="grid-area[1/1] flex items-center justify-center md:justify-start">
                <div tw="text-center relative z-20 md:w-1/2 px-8 md:p-12">
                  <StaticImage
                    src="../images/phonak-black.png"
                    alt="Phonak hearing aid"
                    quality={90}
                    width={380}
                    placeholder="tracedSVG"
                  />
                  <h2 tw="mb-5 font-display text-white text-[40px] letter-spacing[2.5px] lg:(mb-8 text-[50px] letter-spacing[3.5px]) line-height[60px] uppercase">
                    Invisible perfection
                  </h2>
                  <p tw="font-light mb-5 max-w-prose">
                    The Ear Place stock a range of highly fashionable yet
                    discreet hearing aids that blend in with your lifestyle.
                  </p>
                  <button css={[s.baseButton, s.whiteButton]}>
                    <Link to="/hearing-aid-brands">
                      <span tw="font-display font-semibold uppercase text-base leading-none">
                        Find Out More
                      </span>
                    </Link>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <LocationBlock />
      </main>
    </>
  );
};

export default EarLab;
